<template>
  <div class="Product">
    <div class="bigImg">
    </div>
    <div class="amTitle">
      <p @click="changeModel(1)">
        <span :class="control === 1 ? 'clickSpan' : '' ">产品介绍</span>
        <img v-if="control === 1" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(2)">
        <span :class="control === 2 ? 'clickSpan' : '' ">账户介绍</span>
        <img v-if="control === 2" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(3)">
        <span :class="control === 3 ? 'clickSpan' : '' ">产品细则</span>
        <img v-if="control ===3" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(4)">
        <span :class="control === 4 ? 'clickSpan' : '' ">今日汇率</span>
        <img v-if="control === 4" src="../../static/index/amRed.png" alt="">
      </p>
    </div>
    <div class="contentProduct">
      <keep-alive>
        <product-first v-if="control === 1"></product-first>
      </keep-alive>
      <keep-alive>
        <product-second v-if="control === 2"></product-second>
      </keep-alive>
      <keep-alive>
        <product-third v-if="control === 3"></product-third>
      </keep-alive>
      <keep-alive>
        <product-fourth v-if="control === 4"></product-fourth>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ProductFirst from './product/productFirst.vue';
import ProductSecond from './product/productSecond.vue';
import ProductThird from './product/productThird.vue';
import ProductFourth from './product/productFourth.vue';
export default {
  name:'Product',
  components:{
    ProductFirst,
    ProductSecond,
    ProductThird,
    ProductFourth,
  },
  metaInfo: {  
      title: '红狮金业,贵金属投资,现货黄金白银交易,伦敦金伦敦银开户,黄金交易投资',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
  data() {
    return {
      control:1,
    }
  },
  methods:{
    changeModel(key){
      this.control = key;
    }
  },
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
    // 通过上个页面传来的参数进行control的控制；
    if(this.$route.query.key !== undefined){
      this.control = Number(this.$route.query.key);
    }
  },
  watch: {
    $route(nv){
      this.control = nv.query.key;
    }
  }
}
</script>

<style lang="less" scoped>
  .Product{
    width: 100%;
    height: auto;
    .bigImg{
      width: 100%;
      height: 600px;
      background: url('../../static/product/banner.jpg') no-repeat;
      background-size: cover;
    }
    .amTitle{
      width: 1216px;
      height: 100%;
      margin: 80px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 90px;
        span{
          color: #666666;
          font-size: 32px;
          letter-spacing: 2px;
          margin-bottom: 30px;
        }
        .clickSpan{
          color: #000000;
        }
      }
    }
    .contentProduct{
      width: 1216px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 500px) {
    .Product{
      box-sizing: border-box;
      .bigImg{
        width: 100%;
        height: 450px;
        background: url('../../static/index/wapImg/productBanner.jpg') no-repeat;
        background-size: cover;
      }
      .amTitle{
        box-sizing: border-box;
        width: 100%;
        margin: 30px auto;
        display: -webkit-box;
        white-space: nowrap;
        overflow-x: auto;
        p{
          margin-right: 20px;
          span{
            font-size: 18px;
            letter-spacing: 0;
            margin-bottom: 10px;
          }
          img{
            height: 2px;
          }
        }
      }
      .amTitle::-webkit-scrollbar {
        display: none;
      }
      .contentProduct{
        width: 100%;
      }
    }
  }
</style>