<template>
  <div class="productSecond">
    <div class="secondTable">
      <ul>
        <li>账户级别</li>
        <li>伦敦金点差优惠</li>
        <li>伦敦银点差优惠</li>
        <li>升级所需积分</li>
      </ul>
      <ul>
        <li>L1</li>
        <li>$20/手</li>
        <li>$75/手</li>
        <li>0</li>
      </ul>
      <ul>
        <li>L2</li>
        <li>$21/手</li>
        <li>$76/手</li>
        <li>1000</li>
      </ul>
      <ul>
        <li>L3</li>
        <li>$22/手</li>
        <li>$77/手</li>
        <li>5000</li>
      </ul>
      <ul>
        <li>L4</li>
        <li>$23/手</li>
        <li>$78/手</li>
        <li>10000</li>
      </ul>
      <ul>
        <li>L5</li>
        <li>$24/手</li>
        <li>$79/手</li>
        <li>20000</li>
      </ul>
      <ul>
        <li>L6</li>
        <li>$25/手</li>
        <li>$80/手</li>
        <li>40000</li>
      </ul>
      <ul>
        <li>L7</li>
        <li>$26/手</li>
        <li>$81/手</li>
        <li>60000</li>
      </ul>
      <ul>
        <li>...</li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <ul>
        <li>L30</li>
        <li>$49/手</li>
        <li>$104/手</li>
        <li>…</li>
      </ul>
    </div>
    <ul class="productBottom">
      <li>账户级别说明：</li>
      <li>1. 开户后，每位用户起点一样，用户起始账户级别为1级，最高可升级至30级，每升一级，可享受更高的点差优惠；</li>
      <li>2. 用户可通过赚取积分，升级账户级别，获取更多点差优惠，从而减少交易成本，最高可达0成本交易；</li>
      <li>3. 层级只升不降，近期无交易打算，也不会有降级风险，本公司会为您保留账户层级；</li>
      <li>4. 有关账户升级详细内容，欢迎下载app查阅；</li>
      <li>5. 本公司对此优惠保留最终解释权。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name:'productSecond',
}
</script>

<style lang="less" scoped>
  .productSecond{
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 100px;
    .secondTable{
      display: flex;
      flex-direction: column;
      ul{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        li{
          text-align: center;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        li:nth-child(1){
          width: 16.6%;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding-left: 60px;
        }
        li:nth-child(2){
          width: 16.6%;
        }
        li:nth-child(3){
          width: 32.4%;
        }
        li:nth-child(4){
          width: 32.4%;
        }
      }
      ul:nth-child(1){
        background-color: #e8edf1;
        li:last-child{
          width: 34.4%;
          background: url('../../../static/product/image.png') no-repeat;
          background-size: cover;
          color: #ffeec0;
        }
      }
      ul:nth-child(even){
        li:last-child{
          width: 34.4%;
          background-color: #f5f6fa;
        }
      }
      ul:nth-child(odd){
        background-color: #f7f8fa;
        li:last-child{
          width: 34.4%;
          background-color: #e9e9f5;
        }
      }
      // ul:nth-child(4){
      //   li:last-child{
      //     width: 34.4%;
      //     background-color: #f5f6fa;
      //   }
      // }
      // ul:nth-child(5){
      //   background-color: #f7f8fa;
      //   li:last-child{
      //     width: 34.4%;
      //     background-color: #e9e9f5;
      //   }
      // }
      // ul:nth-child(6){
      //   background-color: #f7f8fa;
      //   li:last-child{
      //     width: 34.4%;
      //     background-color: #f5f6fa;
      //   }
      // }
    }
    .productBottom{
      width: 1416px;
      margin-top: 75px;
      li{
        color: #949494;
        line-height: 30px;
      }
      li:nth-child(1){
        color: #333333;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .productSecond{
      padding: 0 5px 30px 5px;
      .secondTable{
        ul{
          height: 100%;
          li{
            font-size: 14px;
            height: 100%;
            padding: 20px 0;
          }
          li:nth-child(1){
            padding-left: 0;
          }
        }
      }
      .productBottom{
        margin-top: 20px;
        width: 100%;
        li{
          font-size: 14px;
        }
        li:nth-child(1){
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>